<template>
  <!-- Start Footer Style Two  -->
  <div
    class="footer-style-2 ptb--30 bg_image bg_image--1"
    data-black-overlay="6"
  >
    <div class="wrapper plr--80 plr_sm--20">
      <v-row align="center">
        <v-col lg="4" md="12" sm="12" cols="12">
          <div class="inner">
            <div class="logo text-lg-left text-center mb_sm--20">
              <router-link to="/">
                <img height="64px" style="display: inline" :src="logo" alt="Logo images"/>
              </router-link>
            </div>
          </div>
        </v-col>
        <!-- End .col -->
        <v-col lg="4" md="12" sm="12" cols="12">
          <div class="inner text-center">
            <ul class="social-share rn-lg-size d-flex justify-center liststyle">
              <li v-for="(social, i) in socialList" :key="i">
                <a target="_blank" :href="social.url"
                             :aria-label="social.label">
                  <i class="fab"  :class="social.icon"></i>
                </a>
              </li>
            </ul>
          </div>
        </v-col>
        <!-- End .col -->
        <v-col lg="4" md="12" sm="12" cols="12">
          <div class="inner text-lg-right text-center mt_md--20 mt_sm--20">
            <div class="text">
              <p style="margin-bottom: 0">
                Alingcamp 51A - 8111 BV Heeten - Telefoon: 06 48 64 75 61
              </p>
              <ul class="ft-link" style="margin-bottom: 0">
                <li v-for="(mail, i) in mailList" :key="i">
                  <a :href="mail.to">{{ mail.mailItem }}</a>
                </li>
              </ul>
              <p>
                Copyright © {{ new Date().getFullYear() }} Rosa Kiekebosch
              </p>
            </div>
          </div>
        </v-col>
        <!-- End .col -->
      </v-row>
    </div>
  </div>
  <!-- End Footer Style Two  -->
</template>

<script>
  export default {
    data() {
      return {
        logo: require("../../assets/images/logo/logo-256-inverted.png"),
        socialList: [
          {
            icon: "fa-linkedin-in",
            url: "http://www.linkedin.com/in/rosakiekebosch",
            label: "Rosa Kiekebosch on Linked-In"
          },
        ],
        mailList: [
          {
            mailItem: "info@rosakiekebosch.nl",
            to: "mailto:info@rosakiekebosch.nl",
          },
        ],
      };
    },
  };
</script>
